import React, { useContext } from 'react';
import Hero from '../Hero/Hero';
import ImageBlock from '../ImageBlock';
import TextImageBlock from '../TextImageBlock';
import Features from '../Features';
import Partners from '../Partners';
import StoryText from '../StoryText';
import { useContentful } from '../contentful-context';
import { getImageUrlByName } from '../utils';
import { Helmet } from "react-helmet";



function Home() {
  const content = useContentful();

  if (!content.home.length) {
    return null;
  }

  var mavenInsideView = getImageUrlByName(content.images, 'maven-inside-view');

  const first = content.home.find(item => item.sectionId.indexOf('1') != -1);
  const second = content.home.find(item => item.sectionId.indexOf('2') != -1);
  const third = content.home.find(item => item.sectionId.indexOf('3') != -1);
  const fourth = content.home.find(item => item.sectionId.indexOf('4') != -1);

  return (
    <div className='px-10 md:px-20 font-bai'>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Hero video />
      <div className='flex flex-wrap justify-center bg-white'>
        <StoryText
          title={first.header}
          body={first.section}
        />
      </div>
      <ImageBlock imageUrl={mavenInsideView} />
      <StoryText
        title={second.header}
        body={second.section}
      />
      <TextImageBlock
        title={third.header}
        body={third.section}
      />
      <Features />
      {/* <Partners /> */}
    </div>
  )
}

export default Home
