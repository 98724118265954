import React from 'react';
import Animate from './Animate';


export default function StoryText({ title, subtitle, body }) {
    return (
        <div className='bg-white w-full mx-auto text-start flex flex-col justify-center py-10 gap-3'>
            <Animate>
                {title && (<h1 className={`md:text-3xl text-xl font-semibold text-start sm:text-center`}>{title}</h1>)}
            </Animate>
            {/* <Animate> */}
                {subtitle && (<p className={`md:text-2xl text-xl text-[#a13424] md:text-center text-starts`}>{subtitle}</p>)}
            {/* </Animate> */}
            <h2
                className={`md:py-3 text-gray-600 text-justify ${title ? 'md:text-lg text-base' : 'md:text-4xl sm:text-2xl text-2xl'}`}
            >
                {body}
            </h2>
        </div>
    )
}