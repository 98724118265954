// src/App.js

import React from 'react';
import { Link } from 'react-router-dom';
import { useContentful } from "./contentful-context";
import { sortBy } from './utils'


function InterestProfiles() {
  const content = useContentful();

  var profiles = sortBy(content.profiles, 'order');

  if (!content.profiles.length) {
    return null;
  }

  return (
    <div className="min-h-screen flex flex-col items-center p-10 sm:p-20 font-bai text-justify bg-interest-profiles bg-cover bg-center bg-blend-lighten">
      <h1 className="text-center text-xl sm:text-4xl font-medium mb-10 text-white opacity-0">
        {/* Want to help us change the game? */}
      </h1>
      <div className="flex flex-wrap justify-center w-full max-w-6xl sm:p-10 my-auto">
      {profiles.map(profile => {
        return (
          <Card
            number={profile.order.toString()}
            title={profile.title}
            text={profile.body}
            buttonText={profile.botton}
            formId={profile.hbsformid}
          />
        )
      })}
      </div>
    </div>
  );
}

function Card({ number, title, text, buttonText, formId }) {
  return (
    <Link to={`/contact/${formId}`} className="w-full sm:w-1/2 lg:w-1/4 p-4 hover:cursor-pointer">
      <div className="bg-[#a43424] text-white shadow p-6 flex flex-col h-full bg-opacity-85 hover:bg-opacity-100 rounded-lg hover:rounded-none">
        {/* <h2 className="text-6xl font-semibold mb-10">{number}</h2> */}
        <h2 className="text-lg sm:text-xl font-semibold mb-1">{title}</h2>
        <p className="text-sm sm:text-base text-white mt-5 flex-grow">{text}</p>
        {/* <h2 className="text-black py-2 rounded hover:text-white mb-4 sm:text-2xl underline hover:cursor-pointer">
          {buttonText}
        </h2> */}
      </div>
    </Link>
  );
}

export default InterestProfiles;
