function getImageUrl(content){
    const { image } = content;
    const { file } = image.fields;

    return file.url;
}

function getImageUrlByName(images, name){
    const content = images.find(img => img.id == name);
    return getImageUrl(content);
}


function getImageUrls(content){
    const { images } = content;
    let imageUrls = [];
    for (let i = 0; i < images.length; i++) {
        const { file } = images[i];
        imageUrls.push(file.url)
    }

    return imageUrls;
}

function sortBy(array, key) {
    return array.sort((a, b) => {
      if (parseInt(a[key]) < parseInt(b[key])) {
        return -1;
      }
      if (parseInt(a[key]) > parseInt(b[key])) {
        return 1;
      }
      return 0;
    });
  }

export { getImageUrl, getImageUrlByName, sortBy }
