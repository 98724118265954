import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useContentful } from "../contentful-context";
import { getImageUrl } from '../utils';
import { FaShower } from "react-icons/fa6";
import { FaBed } from "react-icons/fa6";
import ImageViewer from 'react-simple-image-viewer';
import { Helmet } from "react-helmet";



function Features({ features }) {

    features = features.split(',')
    return (
        <div className='flex justify-center text-black pt-5 sm:px-10'>
        <div className='text-center flex flex-col items-center px-5'>
            <div className='flex flex-row items-center'>
                <FaBed size={40} color='black' />
                <div className='text-xs md:text-lg pl-2 font-bold'>{features[0]}</div>
            </div>
            <p className='text-xs md:text-md font-semibold'>
                Bedrooms
            </p>
        </div>
        <div className='text-center flex flex-col items-center px-5'>
            <div className='flex flex-row items-center'>
                <FaShower size={40} color='black' />
                <div className='text-xs md:text-lg pl-2 font-bold'>{features[1]}</div>
            </div>
            <p className='text-xs md:text-md font-semibold'>
                Bathrooms
            </p>
        </div>
        <div className='text-center flex flex-col items-center px-5'>
            <div className='flex flex-row items-center md:pb-0'>
                <div className='text-xs md:text-lg font-bold pt-3 sm:pt-1'>{features[2]}</div>
            </div>
            <p className='text-xs md:text-md font-semibold pt-3 sm:pt-2'>
                Sq. Ft.
            </p>
        </div>
    </div>
    )
}

export default function ModelView() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const { name } = useParams();
    const content = useContentful();
    let images = [];

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    if (!content.models.length) {
        return null;
    }

    const model = content.models.find(item => item.name.toLowerCase() == name);

    if (!model) {
        return null;
    }

    const openImage = (url) => {
        window.open(url, '_blank')
    }

    const url = getImageUrl(model);

    console.log(model)


    const { dRender, floorPlan } = model;

    const { extendedImage, extendedImageSecond } = model;

    const extendedImageUrl = extendedImage.fields.file.url;
    const extendedImageSecondUrl = extendedImageSecond?.fields?.file?.url;
    const renderImageUrl = dRender.fields.file.url;
    const floorPlanImageUrl = floorPlan.fields.file.url;
    const secondStoryRenderUrl = model.type == 'Two Story' ? model.dRenderSecondStory.fields.file.url : null;
    const secondStoryFloorPlanUrl = model.type == 'Two Story' ? model.secondStoryFloorPlan.fields.file.url : null;

    images.push(floorPlanImageUrl);
    images.push(renderImageUrl);

    if(model.type == 'Two Story'){
        images.push(secondStoryFloorPlanUrl);
        images.push(secondStoryRenderUrl);
    }



    const { body, features } = model;

    return (
        <div className="bg-white px-10 sm:px-10 md:px-20 py-10 font-bai">
            <Helmet>
                <title>View - {name.toUpperCase()}</title>
            </Helmet>
            <h2 className="text-center text-4xl font-semibold md:visible">{name.toUpperCase()}</h2>
            <div className="flex flex-col sm:flex-row justify-center items-start sm:p-10 align-baseline">
                <div className="pt-10 mx-auto w-[95%]">
                    <img src={url} />
                </div>
                <div className="w-[100%] sm:p-10 pb-5 flex flex-col">
                    <Features features={features[0]} />
                    <div className="flex justify-center">
                        <p className="py-5 text-sm sm:text-lg text-justify max-w-[500px]">
                            {body && body.length > 0 && body}
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex sm:flex-row flex-col justify-center content-center items-center sm:p-10">
                <div className="font-semibold">First Story</div>
                <img src={floorPlanImageUrl} onClick={() => openImage(extendedImageUrl)} className='sm:w-[50%] hover:cursor-pointer' />
                <img src={renderImageUrl} onClick={() => openImage(renderImageUrl)} className='sm:w-[50%]' />
            </div>
            <div className="flex sm:flex-row flex-col justify-center items-center sm:p-10">
                {secondStoryFloorPlanUrl && <div className="font-semibold">Second Story</div>}
                {secondStoryFloorPlanUrl && <img src={secondStoryFloorPlanUrl} onClick={() => openImage(extendedImageSecondUrl)} className='sm:w-[50%] hover:cursor-pointer' />}
                {secondStoryRenderUrl && <img src={secondStoryRenderUrl} onClick={() => openImage(secondStoryRenderUrl)} className='sm:w-[50%]' />}
            </div>
        </div>
    )
}