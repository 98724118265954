import * as React from 'react';
import { Spinner } from '@contentful/f36-spinner'
import { createClient } from 'contentful'
import env from "react-dotenv";

const client = createClient({
  space: 'kridrljmiw7v',
  accessToken: 'RYvy9WaFPcDKrzfPoSSU7MkPDginVmvUqZ293m5Blps'
})

const ContentfulContext = React.createContext(null);

const states = ['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY']

const form = [
    {
      label: "First Name",
      name: "firstName",
      type: "text",
      placeholder: "First Name",
      required: true
    },
    {
      label: "Last Name",
      name: "lastName",
      type: "text",
      placeholder: "Last Name",
      required: true
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      placeholder: "Email",
      required: true
    },
    {
      label: "Phone Number",
      name: "phone",
      type: "tel",
      placeholder: "Phone",
      required: true
    },
    {
      label: "Interest Profile",
      name: "interestProfile",
      type: "select",
      placeholder: "Please select the profile that best matches your interest",
      options: [
        "Builder",
        "Architect",
        "Investor",
        "Homeowner"
      ],
      required: true
    },
    {
      label: "Please describe your interest",
      name: "description",
      type: "textarea",
      placeholder: "I would like...",
      required: true
    },
    {
      label: "Build State",
      name: "buildState",
      type: "select",
      placeholder: "Please Select",
      options: [
        ...states
      ]
    },
    {
      label: "Build Date",
      name: "buildDate",
      type: "date",
      placeholder: "Select a date"
    }
  ];
  


function ContentProvider({ children }){
    const [content, setContent] = React.useState({ 
        home: [], 
        story: [], 
        product: [], 
        features: [], 
        mediaFeatures: [], 
        hero: [],
        footer: [],
        images: [],
        models: [],
        faqs: [],
        phases: [],
        fields: [],
        profiles: []
    });

    React.useEffect(() => {
      async function getContent(){
        const sectionContent = await client.getEntries({ content_type: 'section' })
        const featureContent = await client.getEntries({ content_type: 'feature' })
        const mediaFeatureContent = await client.getEntries({ content_type: 'mediaFeature' })
        const imageContent = await client.getEntries({ content_type: 'images' })
        const modelContent = await client.getEntries({ content_type: 'model' })
        const faqContent = await client.getEntries({ content_type: 'faq' })
        const phaseContent = await client.getEntries({ content_type: 'phase' })
        const profileContent = await client.getEntries({ content_type: 'interestProfile' })


        const sections = sectionContent.items.map(({ fields }) => {
            return { ...fields }
        });

        const features = featureContent.items.map(({ fields }) => {
            return { ...fields }
        });

        const mediaFeatures = mediaFeatureContent.items.map(({ fields }) => {
            return { ...fields }
        });

        const images = imageContent.items.map(({ fields }) => {
            return { ...fields }
        });

        const models = modelContent.items.map(({ fields }) => {
            return { ...fields }
        });

        const faqs = faqContent.items.map(({ fields }) => {
            return { ...fields }
        });

        const phases = phaseContent.items.map(({ fields }) => {
            return { ...fields }
        });

        const profiles = profileContent.items.map(({ fields }) => {
          return { ...fields }
        });

        console.log(profiles)

        const home = sections.filter(item => item.sectionId.indexOf('home') != -1);
        const story = sections.filter(item => item.sectionId.indexOf('story') != -1);
        const product = sections.filter(item => item.sectionId.indexOf('product') != -1);
        const hero = sections.filter(item => item.sectionId.indexOf('hero') != -1);
        const footer = sections.filter(item => item.sectionId.indexOf('footer') != -1);

        setContent({ home, story, product, features, mediaFeatures, hero, footer, images, models, faqs, phases, profiles, fields: form });
      }
  
      getContent();
  
    }, []);

    const value = React.useMemo(() => content, [content]);

    return <ContentfulContext.Provider value={value}>{children}</ContentfulContext.Provider>
}

function useContentful(){
    const context = React.useContext(ContentfulContext);
    if (context === undefined) {
        throw new Error('useContentful must be used within a ContentProvider')
    }
    return context    
}


export { ContentProvider, useContentful }
